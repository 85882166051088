import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Kiosk from "../views/Kiosk.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Kiosk",
    component: Kiosk,
  },
  {
    path: "/rent/:id",
    name: "Rent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Rent.vue"),
  },
  {
    path: "/return",
    name: "Return",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/browse",
    name: "Browse",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },
  {
    path: "/admin/list_games",
    name: "Admin_List_Games",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin/ListGames.vue"),
  },
  {
    path: "/admin/add_game",
    name: "Admin_Add_Game",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin/AddGame.vue"),
  },
  {
    path: "/admin/add_game/:gameId",
    name: "Admin_Add_Game2",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin/AddGame2.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
