export default {
    API: {
        endpoints: [
            {
                name: 'main',
                endpoint: 'https://dev.bgrent.codewerk.co.uk/api/',
                

            }
        ]
    }
}