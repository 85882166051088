<template>
	<div class="kiosk-screen">
				<div class="row">
					<div class="col-lg-3">
						<div class="card" v-on:click="startRent()">
							<img src="../assets/images/root.webp" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Rent</h2>
									<p class="f-s-12">Borrow one of our games</p>
									<!-- <div class="btn btn-primary btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Rent now
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/return')">
							<img src="../assets/images/photos/IMG_4285.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Return</h2>
									<p class="f-s-12">Completed the game?</p>
									<!-- <div class="btn btn-success btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Return now
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/browse')">
							<img src="../assets/images/photos/IMG_4249.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Browse</h2>
									<p class="f-s-12">Look through the Library's selection</p>
									<!-- <div class="btn btn-light btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Browse
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/admin')">
							<img src="../assets/images/photos/IMG_4223.jpg" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Admin</h2>
									<p class="f-s-12">Look through the Library's selection</p>
									<!-- <div class="btn btn-light btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Jump
									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>                
				<div class="row">
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-primary">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">{{ count }}</h2><span class="text-white m-t-10 f-s-20">games</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-success">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">1251</h2><span class="text-white m-t-10 f-s-20">user</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-warning">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">1250</h2><span class="text-white m-t-10 f-s-20">user</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		<!-- </div> -->
			
</template>

<script>
export default {
  data: () => ({
	   count : 5,
  }),
  methods: {
	  startRent: function(){
		  const vm = this;
			vm.$swal({
				imageUrl: 'https://localhost:8080/img/barcode-scan.png',
				imageHeight:300, 
				customClass: 'scannerPopUp',
				input: 'text'
			}).then((result) => {
				if(result.isConfirmed){
					vm.$router.push('/rent/'+result.value);
				}
			})
	  }
  },
  mounted () {
	document.body.classList.add('v-light', 'horizontal-nav')
  },
  destroyed () {
	document.body.classList.remove('bg-light', 'login')
  },
}
</script>

<style>
body.v-light .card:hover{
    border: 2px solid orange;
}
</style>