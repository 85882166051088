<template>
  <div id="app bg-rent">
    <!-- <CodeCapture/> -->
    <div id="main-wrapper" class="show">        
      <!-- content body -->
      <div class="content-body">
        <div class="container">
          <div class="col-xs-2">
            Beep
          </div>
          <div class="col-xs-10">
            <router-view />
          </div>
        <!-- #/ container -->
        </div>
        <!-- #/ content body -->
        <!-- footer -->
        <!-- <div class="footer">
            <div class="copyright">
                <p>Copyright &copy; <a href="https://codewerk.co.uk">Codewerk</a> 2021</p>
            </div>
        </div> -->
        <!-- #/ footer -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.kiosk-screen{
  margin-top: 200px;
}
#bg-rent #main-wrapper{
  max-width: 1600px;
  .container{
    max-width: 1600px;
  }
}
   @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;900&display=swap');

    #app {
  font-family: 'Heebo', sans-serif;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.scannerPopUp{
  input.swal2-input{ opacity: 0 !important; position: absolute; top: 0px;}
  div.swal2-actions{ opacity: 0 !important; position: absolute; top: 0px;}
}
</style>

<script>
// import CodeCapture from './components/CodeCapture.vue';

export default {
  // components: { CodeCapture },
  mounted () {
    document.body.classList.add('v-light', 'horizontal-nav')
  },
  destroyed () {
    document.body.classList.remove('bg-light', 'login')
  },
}
</script>